<template>
  <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="localtableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>

      <el-table-column
        prop="recipientNumber"
        label="受种者编号"
        show-overflow-tooltip
        width="170"
      >
      </el-table-column>
      <el-table-column
        prop="vaccinationFileNumber"
        label="预防接种档案编号"
        width="180"
      >
      </el-table-column>

      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="gender" label="性别"> </el-table-column>
      <el-table-column prop="dateOfBirth" label="出生日期" width="110">
      </el-table-column>
      <el-table-column prop="contact" label="联系方式" width="110">
      </el-table-column>

      <el-table-column prop="idNumber" label="身份证件号" width="170">
      </el-table-column>
      <el-table-column
        prop="crowdClassification"
        label="人群分类"
        width="110"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="personnelClassification"
        label="人员类别"
        width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="groups" label="22类人群"> </el-table-column>
      <el-table-column
        prop="residentialAddress"
        label="现居住地址"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="vaccinationOutpatientService" label="接种门诊">
      </el-table-column>

      <el-table-column prop="workUnits" label="工作单位"> </el-table-column>
      <el-table-column prop="manufacturer" label="生产厂家"> </el-table-column>
      <el-table-column prop="inoculationTime" label="接种时间">
      </el-table-column>
      <el-table-column prop="dose" label="剂次"> </el-table-column>

      <el-table-column label="操作" fixed="right" v-slot="scope" width="80" v-if="showControl">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
</template>

<script>
import {
  deleteVaccineInoculationInfoById
} from "../../../../api/epidemiccontrolVaccineInoculationInfo/index";
export default {
 props: {
    tableData: [],
    showControl: false, //隐藏操作区
     localtableHeight:"",
  },
  data(){
      return{
      }
  },methods:{
    //删除
    deleteOption(row) {
      this.$confirm("确定要删除此数据", "数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          deleteVaccineInoculationInfoById(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
               this.$emit("deleteSuccess")
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  }
}
</script>

<style>

</style>