import request from "@/base/util/request.js";
// 
// 删除
export const deleteVaccineInoculationInfoById = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolVaccineInoculationInfo/deleteVaccineInoculationInfoById',
        method: 'get',
        params
    })
}

// GET 
//分页查询 
export const queryVaccineInoculationInfoByPage = (data) => {
        return request({
            url: 'epidemicControl/epidemiccontrolVaccineInoculationInfo/queryVaccineInoculationInfoByPage',
            method: 'post',
            data
        })
    }
    //数据导入
export const importVaccineInoculationInfosPath = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolVaccineInoculationInfo/importVaccineInoculationInfosPath',
        method: 'get',
        params
    })
}